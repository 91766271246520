import React from 'react';
import { graphql } from 'gatsby';
import camelCase from 'lodash/camelCase';
import intersection from 'lodash/intersection';

// Components
import {
  ChampionsSeasonCards,
  EmptyTable,
  PlaceholderCard,
  SeasonImageTitles,
} from 'components';

// Styles
import { PostFeed } from 'styles';

// Layouts
import { PageLayout, SEOContent } from 'layouts';

// Templates
import { BasicTemplate } from 'templates';

// Hooks
import { getDistinctMeetNames } from 'hooks';

// Utils
import { isEven } from 'utils';

// Types
import { Season } from 'types';

export interface Props {
  data: {
    [key in string]: MeetName;
  };
  path: string;
  pageContext: {
    isCreatedByStatefulCreatePages: boolean;
    season: Season;
  };
}

export interface MeetName {
  distinct: string[];
}

export type XCTypes =
  | SeasonImageTitles.ALL_STATE
  | SeasonImageTitles.STATEWIDE
  | SeasonImageTitles.EASTERN_MASS
  | SeasonImageTitles.CENTRAL_MASS
  | SeasonImageTitles.WESTERN_MASS;

// Keep this order.
const preferredXCOrder: XCTypes[] = [
  SeasonImageTitles.ALL_STATE,
  SeasonImageTitles.STATEWIDE,
  SeasonImageTitles.EASTERN_MASS,
  SeasonImageTitles.CENTRAL_MASS,
  SeasonImageTitles.WESTERN_MASS,
];

const CrossCountryChampionsLandingPage = (props: Props) => {
  const season = 'Cross country';
  // Match the key name from GQL.
  const properSeason = camelCase(season);
  const meetNames = getDistinctMeetNames(props.data[properSeason]);
  const meets = intersection(preferredXCOrder, meetNames) as XCTypes[];
  const hasEvenMeetCount = isEven(meets.length);

  const title = meetNames.length
    ? `${season} champions`
    : `No ${season} champions yet`;

  return (
    <SEOContent title={title}>
      <PageLayout>
        <BasicTemplate templateTitle={title}>
          <section css={[PostFeed]}>
            {meets.length ? (
              <>
                <ChampionsSeasonCards
                  dataArray={meets}
                  season={season}
                  {...props}
                />
                {!hasEvenMeetCount && <PlaceholderCard />}
              </>
            ) : (
              <EmptyTable />
            )}
          </section>
        </BasicTemplate>
      </PageLayout>
    </SEOContent>
  );
};

export default CrossCountryChampionsLandingPage;

export const query = graphql`
  query {
    crossCountry: allAirtable(
      filter: { table: { eq: "Cross Country Champions" } }
    ) {
      distinct(field: data___meet_name)
    }
  }
`;
